<template>
	<div class="submit">
		<ZyroFieldText
			v-model="buttonText"
			:placeholder="$t('builder.editForm.submitButtonLabel')"
			:label="$t('builder.editForm.submitButtonLabel')"
		/>
		<ZyroLabel>
			{{ $t('builder.editForm.buttonPosition') }}
		</ZyroLabel>
		<ZyroIconControls
			:current.sync="buttonAlign"
			:icons="$options.iconsAlign"
		/>
		<ZyroLabel class="submit__label">
			{{ $t('builder.editForm.submitActionLabel') }}
		</ZyroLabel>
		<ZyroSegmentControl
			class="submit__tabs-control"
			:controls="TABS"
			:active-control="currentTab"
			@change="currentTab = $event"
		/>
		<template v-if="currentTab.id === SUBMIT_ACTIONS.SHOW_MESSAGE">
			<ZyroTextArea
				v-model="successMessage"
				:placeholder="$t('builder.editForm.successMessagePlaceholder')"
				:bold="false"
			/>
			<p class="editor-text z-body-small">
				{{ $t('builder.editForm.successMessageExplanation') }}
			</p>
		</template>
		<template v-else>
			<ZyroLabel class="submit__label">
				{{ $t('common.page') }}
			</ZyroLabel>
			<ZyroDropdown
				:options="pages"
				:current.sync="currentPage"
			/>
		</template>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { useBuilderStyles } from '@/components/builder-controls/useBuilderStyles';
import i18n from '@/i18n/setup';
import { capitalizeFirstLetter } from '@/utils/modifyString';
import { cloneDeep } from '@/utils/object';
import { SUBMIT_ACTIONS } from '@user/components/grid-components/form/constants';

const iconsAlign = [
	{
		value: 'start',
		icon: 'align-left-h',
	},
	{
		value: 'center',
		icon: 'align-middle-h',
	},
	{
		value: 'end',
		icon: 'align-right-h',
	},
];

const TABS = [
	{
		id: SUBMIT_ACTIONS.SHOW_MESSAGE,
		title: i18n.t('builder.editForm.showMessage'),
	},
	{
		id: SUBMIT_ACTIONS.LINK_TO_PAGE,
		title: i18n.t('builder.editForm.linkToPage'),
	},
];

export default {
	setup() {
		const {
			getStyleValue,
			getStyleKey,
		} = useBuilderStyles();

		return {
			getStyleValue,
			getStyleKey,
			TABS,
			SUBMIT_ACTIONS,
		};
	},
	iconsAlign,
	data() {
		return {
			id: '',
			currentElementBeforeEdit: null,
			selectedPage: null,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapState('gui', [
			'isMobileView',
			'isMobileScreen',
		]),
		...mapGetters(['currentElement']),
		...mapGetters('pages', ['defaultPages']),
		buttonText: {
			get() {
				return this.currentElement.submitButtonData.content;
			},
			set(event) {
				this.setElementData({ data: { submitButtonData: { content: event.target.value } } });
			},
		},
		successMessage: {
			get() {
				if (this.currentElement.settings.successMessage) {
					return this.currentElement.settings.successMessage;
				}

				this.setElementData({ data: { settings: { successMessage: 'Thank you!' } } });

				return '';
			},
			set(event) {
				this.setElementData({ data: { settings: { successMessage: event.target.value } } });
			},
		},
		buttonAlign: {
			get() {
				return this.getStyleValue('align', this.currentElement.submitButtonData.settings.styles);
			},
			set(newValue) {
				const key = this.getStyleKey('align');

				this.setElementData({ data: { submitButtonData: { settings: { styles: { [key]: newValue } } } } });
			},
		},
		pages() {
			// TODO Needs refactoring as there must be a more elegant solution to tackle this.
			return Object.entries(this.defaultPages).map(([pageId, page]) => ({
				pageId,
				title: page.name
					|| capitalizeFirstLetter(page.path.slice(1))
					|| 'Home',
			}));
		},
		currentTab: {
			get() {
				const currentTab = this.currentElement.settings.submitAction || TABS[0];

				return TABS.find((tab) => tab.id === currentTab.id);
			},
			set(value) {
				this.setElementData({ data: { settings: { submitAction: value } } });
			},
		},
		currentPage: {
			get() {
				return this.currentElement.settings.submitRedirectPage || this.pages[0];
			},
			set(value) {
				this.setElementData({ data: { settings: { submitRedirectPage: value } } });
			},
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: mapMutations([
		'pushElementDataToHistory',
		'setElementData',
	]),
};
</script>

<style lang="scss" scoped>
.submit {
	&__tabs-control {
		margin-bottom: 18px;
	}

	&__label {
		margin-bottom: 6px;
	}
}

// TODO: we should look into managing spacing in edit popups better, right now its pretty messy
// stylelint-disable no-descending-specificity
::v-deep {
	.zyro-field-toggle {
		+ .editor-text {
			margin-top: 8px;
		}
	}

	.zyro-field-text {
		+ .zyro-field-toggle {
			margin-top: 32px;
		}
	}

	.editor-text {
		+ .zyro-field-text {
			margin-top: 20px;
		}
	}

	.separator {
		margin: 16px auto;
	}
}

.text {
	&--gray {
		color: $grey-800;
	}
}
</style>
