<template>
	<div class="form-controls">
		<ZyroFieldText
			v-model="name"
			:placeholder="$t('builder.editForm.formName')"
			:label="$t('builder.editForm.formName')"
		/>
		<ZyroFieldToggle
			v-if="false"
			id="sendEmail"
			:label="$t('builder.editForm.emailNotifications')"
			:checked="sendEmail"
			@input="sendEmail = $event"
		/>
		<ZyroFieldText
			v-if="sendEmail"
			v-model="email"
			:placeholder="$t('common.email')"
			:label="$t('common.email')"
		/>
		<ZyroSeparator />
		<p class="editor-text z-body-small">
			{{ $t('builder.editForm.formSubmissionsTable') }}
		</p>

		<p class="editor-text z-body-small text--gray">
			{{ $t('builder.editForm.formSubmissionsExplanation') }}
		</p>
		<ZyroButton
			theme="outline"
			class="form-controls__submissions-button"
			@click="$router.push({
				name: FORMS_SETTINGS_ROUTE,
				params: { formId: formId }
			})"
		>
			{{ $t('builder.editForm.formSubmissionsButton') }}
		</ZyroButton>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
	mapActions,
} from 'vuex';

import i18n from '@/i18n/setup';
import { FORMS_SETTINGS_ROUTE } from '@/router';
import { cloneDeep } from '@/utils/object';

export default {
	setup() {
		return { FORMS_SETTINGS_ROUTE };
	},
	data() {
		return {
			id: '',
			currentElementBeforeEdit: null,
		};
	},
	computed: {
		...mapState([
			'currentElementId',
			'website',
		]),
		...mapGetters(['currentElement']),
		...mapGetters('forms', [
			'getFormEmail',
			'getFormEmailNotifications',
			'getFormName',
		]),
		formId() {
			return this.currentElement.formId;
		},
		name: {
			get() {
				const formName = this.getFormName(this.formId);

				if (formName) {
					return formName;
				}

				this.setFormName({
					formId: this.formId,
					name: this.currentElement.formId === 'subscribeForm'
						? i18n.t('siteSettings.forms.subscribeForm.title')
						: i18n.t('siteSettings.forms.contactForm.title'),
				});

				return '';
			},
			set(event) {
				this.setFormName({
					formId: this.formId,
					name: event.target.value,
				});
				this.$recompute('name');
			},
		},
		email: {
			get() {
				return this.getFormEmail(this.formId);
			},
			set(event) {
				this.setFormEmail({
					formId: this.formId,
					email: event.target.value,
				});
				this.$recompute('email');
			},
		},
		sendEmail: {
			get() {
				return this.getFormEmailNotifications(this.formId);
			},
			set(event) {
				this.setFormEmailNotifications({
					formId: this.formId,
					emailNotifications: event.target.checked,
				});
				this.$recompute('sendEmail');
			},
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: {
		...mapMutations(['pushElementDataToHistory']),
		...mapActions('forms', [
			'setFormEmail',
			'setFormEmailNotifications',
			'setFormName',
		]),
	},
};
</script>

<style lang="scss" scoped>
.form-controls {
	&__submissions-button {
		display: flex;
		justify-content: center;
		margin: 25px auto 11px;
	}
}
// stylelint-disable no-descending-specificity
::v-deep {
	.zyro-field-toggle {
		+ .editor-text {
			margin-top: 8px;
		}
	}

	.zyro-field-text {
		+ .zyro-field-toggle {
			margin-top: 32px;
		}
	}

	.editor-text {
		+ .zyro-field-text {
			margin-top: 20px;
		}
	}

	.separator {
		margin: 16px auto;
	}
}

.text {
	&--gray {
		color: $grey-800;
	}
}
</style>
