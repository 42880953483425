<template>
	<div>
		<ZyroDropdown
			class="dropdown"
			:options="$options.FIELDS"
			:current="$options.DEFAULT_DROPDOWN_FIELD"
			@update:current="addField"
		/>
		<Draggable
			v-model="fields"
			class="fields"
			handle=".field__control-handle"
			fallback-axis="y"
			:force-fallback="true"
			ghost-class="draggable-element"
			easing="cubic-bezier(0.76, 0, 0.24, 1)"
			animation="150"
			direction="vertical"
		>
			<template v-for="(field) in currentElement.settings.schema">
				<FormField
					:key="field.id"
					:is-disabling-last-toggle="isDisablingLastToggle"
					:active-field-id="activeFieldId"
					:field="field"
					:set-data="setFieldData"
					:required-field-count="requiredFieldCount"
					:svg-name="field.svg || $options.DEFAULT_ICON"
					@change="handleValidation"
					@remove-field="removeField"
					@toggle-update="isDisablingLastToggle = $event"
					@update-active-field-id="updateActiveFieldId"
				/>
			</template>
		</Draggable>
	</div>
</template>

<script>
import { nanoid } from 'nanoid';
import Draggable from 'vuedraggable-axis';
import {
	mapGetters,
	mapState,
	mapMutations,
} from 'vuex';

import i18n from '@/i18n/setup';
import { cloneDeep } from '@/utils/object';
import {
	DEFAULT_ICON,
	FIELDS,
} from '@user/components/grid-components/form/constants';

import FormField from './FormField.vue';

export const DEFAULT_DROPDOWN_FIELD = {
	title: i18n.t('builder.editForm.addNewFormField'),
	value: 'add-new',
	svg: 'plus-circle',
};

export default {
	DEFAULT_ICON,
	FIELDS,
	DEFAULT_DROPDOWN_FIELD,
	components: {
		FormField,
		Draggable,
	},
	data() {
		return {
			activeFieldId: null,
			id: '',
			currentElementBeforeEdit: null,
			isDisablingLastToggle: false,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapGetters(['currentElement']),
		fields: {
			get() {
				return this.currentElement.settings.schema;
			},
			set(value) {
				this.setElementData({ data: { settings: { schema: value } } });
			},
		},
		flattenedSchema() {
			return this.fields.flatMap((field) => field.children || field);
		},
		requiredFieldCount() {
			return this.flattenedSchema.filter((item) => this.getIsFieldRequired(item.validation)).length;
		},
	},
	watch: {
		requiredFieldCount(count) {
			if (count > 0) {
				return;
			}

			const stateCopy = cloneDeep(this.fields);
			const stateField = stateCopy[0].children ? stateCopy[0].children[0] : stateCopy[0];
			const getUpdatedValidation = (validation) => (!this.getIsFieldRequired(validation) ? [
				...validation,
				['required'],
			].filter(([rule]) => rule !== 'optional') : validation);

			stateField.validation = getUpdatedValidation(stateField.validation);
			this.setElementData({ data: { settings: { schema: stateCopy } } });
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	methods: {
		...mapMutations([
			'pushElementDataToHistory',
			'setElementData',
		]),
		getIsFieldRequired(validationArray) {
			return !!validationArray.some(([rule]) => rule === 'required');
		},
		setUniqueName(input) {
			if (!input) {
				return `field_${nanoid(3)}`;
			}

			return !this.flattenedSchema.some((item) => item.name === input) ? input : `${input}_${nanoid(3)}`;
		},
		setFieldData(fieldId, field, event, nestedFieldIndex) {
			const { value } = event.target;
			const getModifiedItem = (itemToModify) => ({
				...itemToModify,
				[field]: value,
				name: field === 'inputLabel' ? this.setUniqueName(value) : itemToModify.name,
			});
			const modifiedSchema = this.fields.map((item) => {
				if (fieldId !== item.id) {
					return item;
				}

				return !item.children ? getModifiedItem(item) : {
					...item,
					children: item.children.map((rowItem, index) => (
						index === nestedFieldIndex ? getModifiedItem(rowItem) : rowItem)),
				};
			});

			this.setElementData({ data: { settings: { schema: modifiedSchema } } });
		},
		handleValidation({
			required,
			id,
			nestedFieldIndex,
		}) {
			const stateCopy = cloneDeep(this.fields);
			const index = stateCopy.findIndex((item) => item.id === id);
			const getUpdatedValidation = (validation) => [
				...validation,
				required ? ['required'] : ['optional'],
			].filter(([rule]) => rule !== (required ? 'optional' : 'required'));
			// take out copy by reference
			const stateField = nestedFieldIndex >= 0
				? stateCopy[index].children[nestedFieldIndex] : stateCopy[index];

			stateField.validation = getUpdatedValidation(stateField.validation);
			this.setElementData({ data: { settings: { schema: stateCopy } } });
		},
		updateActiveFieldId(id) {
			this.activeFieldId = this.activeFieldId === id ? null : id;
		},
		addField({ field }) {
			const stateCopy = cloneDeep(this.currentElement.settings);

			if (!field.children) {
				stateCopy.schema.push({
					...field,
					id: nanoid(),
					name: `${field.name}_${nanoid(3)}`,
				});
			} else {
				const updatedChildren = field.children.map((item) => ({
					...item,
					name: `${item.name}_${nanoid(3)}`,
				}));

				stateCopy.schema.push({
					...field,
					id: nanoid(),
					children: updatedChildren,
				});
			}

			this.setElementData({ data: { settings: stateCopy } });
		},
		removeField(fieldId) {
			const updatedSchema = this.fields.filter((field) => field.id !== fieldId);

			this.setElementData({ data: { settings: { schema: updatedSchema } } });
		},
	},
};
</script>

<style lang="scss" scoped>
.fields {
	$popup-head-height: 170px;
	$max-popup-height-larger-screens: 648px;
	$max-popup-height-smaller-screens: 70vh;

	max-height: calc(#{$max-popup-height-smaller-screens} - #{$popup-head-height});
	overflow-y: auto;

	@media screen and (min-width: 1440px) {
		max-height: calc(#{$max-popup-height-larger-screens} - #{$popup-head-height});
	}
}

.dropdown {
	&::v-deep {
		.dropdown {
			&__button {
				cursor: pointer;
				background-color: $light;
			}

			&__icon {
				display: none;
			}
		}
	}
}

.draggable-element {
	::v-deep {
		background-color: rgba($grey-200, 0.5);
	}
}
</style>
