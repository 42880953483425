<template>
	<BaseEditControls
		:block-id="$parent.blockId"
		:block-ref="$parent.blockRef"
		:button-title="$t('builder.editForm.editForm')"
	>
		<ZyroPopupCard
			type="editor"
			:title="$t('builder.editForm.formSettings')"
			:tabs="$options.tabs"
			:current-tab="currentTab"
			@change="currentTab = $event"
			@close="$emit('close')"
		>
			<Component :is="currentTab.component" />
		</ZyroPopupCard>
	</BaseEditControls>
</template>

<script>
import BaseEditControls from '@/components/builder-controls/BaseEditControls.vue';
import i18n from '@/i18n/setup';

import EditFormTabButton from './EditFormTabButton.vue';
import EditFormTabFields from './EditFormTabFields/EditFormTabFields.vue';
import EditFormTabGeneral from './EditFormTabGeneral.vue';
import EditFormTabStyle from './EditFormTabStyle/EditFormTabStyle.vue';

const tabs = [
	{
		title: i18n.t('common.general'),
		component: 'EditFormTabGeneral',
	},
	{
		title: i18n.t('common.fields'),
		component: 'EditFormTabFields',
	},
	{
		title: i18n.t('common.button'),
		component: 'EditFormTabButton',
	},
	{
		title: i18n.t('common.style'),
		component: 'EditFormTabStyle',
	},
];

export default {
	tabs,
	components: {
		BaseEditControls,
		EditFormTabStyle,
		EditFormTabFields,
		EditFormTabButton,
		EditFormTabGeneral,
	},
	data() {
		return { currentTab: this.$options.tabs[0] };
	},
};
</script>
