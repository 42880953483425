<template>
	<div class="style">
		<FormThemeControl
			:selected-theme="theme"
			@change="theme = $event"
		/>
		<ZyroSeparator />
		<ZyroFieldToggle
			id="toggle-form-background"
			:checked="!!currentInnerBackground"
			:label="$t('builder.editForm.addBackground')"
			@change="currentInnerBackground = $event.target.checked ? 'color': ''"
		/>
		<template v-if="!!currentInnerBackground">
			<ZyroBackground
				:background-object.sync="innerBackground"
			/>
		</template>
		<ZyroSeparator v-if="innerBackground.current !== 'image'" />
		<ZyroCssShorthandControl
			:show-vertical="!!currentInnerBackground"
			:title-horizontal="$t('builder.cssShorthandControlRange.horizontal')"
			:value.sync="padding"
			:label="$t('common.padding')"
			bold
			units="px"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { cloneDeep } from '@/utils/object';
import { THEME_LIGHT } from '@user/components/grid-components/form/constants';

export default {
	components: { FormThemeControl: () => import('./FormThemeControl') },
	data() {
		return {
			id: '',
			currentElementBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapGetters(['currentElement']),
		align: {
			get() {
				return this.currentElement.settings.styles.align || '';
			},
			set(newValue) {
				this.setElementData({ data: { settings: { styles: { align: newValue } } } });
			},
		},
		justify: {
			get() {
				return this.currentElement.settings.styles.justify || '';
			},
			set(newValue) {
				this.setElementData({ data: { settings: { styles: { justify: newValue } } } });
			},
		},
		currentInnerBackground: {
			get() {
				return this.currentElement.innerBackground.current || '';
			},
			set(newValue) {
				this.setElementData({ data: { innerBackground: { current: newValue } } });
			},
		},
		innerBackground: {
			get() {
				return this.currentElement.innerBackground || {};
			},
			set(newValue) {
				this.setElementData({ data: { innerBackground: newValue } });
			},
		},
		padding: {
			get() {
				return this.currentElement.settings.styles.gridItemInnerPadding || '0px';
			},
			set(newValue) {
				this.setElementData({ data: { settings: { styles: { gridItemInnerPadding: newValue } } } });
			},
		},
		innerBackgroundColor: {
			get() {
				return this.currentElement.settings.styles.gridItemInnerBackgroundColor || '#FFFFFF00';
			},
			set(newValue) {
				this.setElementData({ data: { settings: { styles: { gridItemInnerBackgroundColor: newValue } } } });
			},
		},
		theme: {
			get() {
				// TODO remove || THEME_LIGHT when mapper is released
				return this.currentElement.settings.theme || THEME_LIGHT;
			},
			set(theme) {
				this.setElementData({
					data: {
						settings: { theme },
						submitButtonData: { settings: { type: theme === THEME_LIGHT ? 'primary' : 'secondary' } },
					},
				});
			},
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: {
		...mapMutations([
			'pushElementDataToHistory',
			'setElementData',
		]),
	},
};
</script>

<style lang="scss" scoped>
.style {
	display: flex;
	flex-direction: column;
}
</style>
