<template>
	<div class="field">
		<div
			class="field__control"
			@click="handleFieldControl"
		>
			<div>
				<ZyroButton
					class="field__control-handle"
					theme="plain"
					color="black"
					icon="move"
					:title="$t('common.move')"
					@mousedown.stop="handleFieldDrag"
					@click.stop
				/>
				<ZyroSvg
					:name="svgName"
					dimensions="16px"
				/>
				<label class="field__name">
					{{ field.children ? field.children[0].inputLabel : field.inputLabel }}
				</label>
			</div>
			<ZyroSvg :name="`chevron-${isOpen ? 'up' : 'down'}-small`" />
		</div>
		<template v-if="field.children">
			<div
				v-for="({ inputLabel, placeholder }, index) in field.children"
				:key="`${field.id}-${index}`"
				:class="computedSettingsClass"
				class="field__settings"
			>
				<ZyroFieldText
					:value="inputLabel"
					:label="$t('builder.editForm.fieldLabel')"
					@input="setData(field.id, 'inputLabel', $event, index)"
				/>
				<ZyroFieldText
					:value="placeholder"
					:label="$t('builder.editForm.placeholderText')"
					@input="setData(field.id, 'placeholder', $event, index)"
				/>
				<ZyroFieldToggle
					:id="`${field.id}-${index}`"
					:label="$t('builder.editForm.fieldIsRequired')"
					:checked="getIsChildFieldRequired(index)"
					:bold="false"
					@click="setIsRequired(field.id, $event, index)"
				/>
				<div
					v-if="isDisablingLastToggle"
					class="field__settings-error z-body-small"
				>
					{{ $t('builder.editForm.disablingLastToggleError') }}
				</div>
				<ZyroButton
					v-if="!isOnlyOneFieldLeft && index === field.children.length - 1"
					color="red"
					@click="$emit('remove-field', field.id)"
				>
					{{ $t('builder.editForm.removeField') }}
				</ZyroButton>
			</div>
		</template>
		<div
			v-else
			:class="computedSettingsClass"
			class="field__settings"
		>
			<ZyroFieldText
				:value="field.inputLabel"
				:label="$t('builder.editForm.fieldLabel')"
				@input="setData(field.id, 'inputLabel', $event)"
			/>
			<ZyroFieldText
				:value="field.placeholder"
				:label="$t('builder.editForm.placeholderText')"
				@input="setData(field.id, 'placeholder', $event)"
			/>
			<ZyroFieldToggle
				v-if="!isOnlyOneFieldLeft"
				:id="field.id"
				:label="$t('builder.editForm.fieldIsRequired')"
				:checked="isFieldRequired"
				:bold="false"
				@click="setIsRequired(field.id, $event)"
			/>
			<div
				v-if="isDisablingLastToggle"
				class="field__settings-error z-body-small"
			>
				{{ $t('builder.editForm.disablingLastToggleError') }}
			</div>
			<ZyroButton
				v-if="!isOnlyOneFieldLeft"
				color="red"
				@click="$emit('remove-field', field.id)"
			>
				{{ $t('builder.editForm.removeField') }}
			</ZyroButton>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		activeFieldId: {
			type: String,
			default: null,
		},
		field: {
			type: Object,
			default: () => ({}),
		},
		svgName: {
			type: String,
			default: 'user',
		},
		setData: {
			type: Function,
			default: () => ({}),
		},
		isDisablingLastToggle: {
			type: Boolean,
			default: false,
		},
		requiredFieldCount: {
			type: Number,
			default: null,
		},
	},
	computed: {
		...mapGetters(['currentElement']),
		computedSettingsClass() {
			return [
				{ 'field__settings--open': this.isOpen },
				{ 'field__settings--error': this.disablingLastToggle && this.isOpen },
			];
		},
		isOpen() {
			return this.activeFieldId === this.field.id;
		},
		isFieldRequired() {
			return this.findRequiredRule(this.field.validation);
		},
		isOnlyOneFieldLeft() {
			return this.currentElement.settings.schema.length === 1;
		},
	},
	methods: {
		// if the element that's being dragged is open, close it.
		handleFieldDrag() {
			if (this.field.id === this.activeFieldId) {
				this.$emit('update-active-field-id', null);
			}
		},
		findRequiredRule(validationArray) {
			return !!validationArray.some(([rule]) => rule === 'required');
		},
		getIsChildFieldRequired(index) {
			return this.findRequiredRule(this.field.children[index].validation);
		},
		handleFieldControl() {
			this.$emit('toggle-update', false);
			this.$emit('update-active-field-id', this.field.id);
		},
		setIsRequired(id, event, nestedFieldIndex) {
			const { checked } = event.target;

			if (this.requiredFieldCount === 1 && !checked) {
				event.preventDefault();
				this.$emit('toggle-update', true);

				return;
			}

			this.$emit('toggle-update', false);
			this.$emit('change', {
				required: checked,
				id,
				nestedFieldIndex,
			});
		},
	},
};
</script>

<style scoped lang="scss">
.field {
	display: flex;
	flex-direction: column;
	border-top: 1px solid $grey-100;

	&__control {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 48px;
		padding: 16px 16px 16px 0;
		cursor: pointer;
	}

	&__control-handle {
		overflow: hidden;
		cursor: move;

		&:focus {
			border: none;
		}
	}

	&__name {
		padding-left: 12px;
	}

	&__settings {
		height: 0;
		padding: 0 16px;
		overflow: hidden;

		&-error {
			display: flex;
			align-items: center;
			height: 36px;
			padding-left: 12px;
			color: $secondary;
			background: $secondary-lighter;
			border-radius: $border-radius-small;
		}

		$this: &;

		&--open {
			height: 100%;
			padding: 16px;
			border: 1px solid $grey-100;

			&#{$this}--error {
				height: 320px;
			}
		}
	}
}
</style>
