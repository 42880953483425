import { render, staticRenderFns } from "./EditFormTabFields.vue?vue&type=template&id=53198e08&scoped=true&"
import script from "./EditFormTabFields.vue?vue&type=script&lang=js&"
export * from "./EditFormTabFields.vue?vue&type=script&lang=js&"
import style0 from "./EditFormTabFields.vue?vue&type=style&index=0&id=53198e08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53198e08",
  null
  
)

export default component.exports